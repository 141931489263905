import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import { backendIP } from './myconfigurations';
import { io } from 'socket.io-client';

function App() {
  const udpServerBackendLink = "http://127.0.0.1:18888"
  const [socket, setSocket] = useState(io(udpServerBackendLink, {
    autoConnect: false,
    transports: ["websocket", "polling"],
  }));

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected to local backend")
      socket.emit("upgrade", "")
    })

    socket.on("status", (data: any) => {
      console.log("status", data)
    })

    socket.on("disconnect", () => {
      console.log("disconnected from local backend")
    })
    socket.connect()
    return () => {
      socket.off("connect")
      socket.off("disconnect")
      socket.off("status")
      socket.close()
    }
  }, [socket])


  const [sitlStatus, setSitlStatus] = useState("Not Started")
  const [userDetails, setUserDetails] = useState({ userEmail: "", password: "", droneID: "", lat: "25.647599838681714", long: "90.32645678880044", asl: "610", heading: "60" })
  const updateUserDetails = () => {
    setUserDetails({ ...userDetails })
  }


  const handleLoginClick = async () => {
    if (userDetails.userEmail !== "" && userDetails.password !== "") {
      try {
        const loginResp = await axios(`${backendIP}/login-user`, {
          method: "POST",
          data: { ...userDetails }
        })
        console.log("loginResp", loginResp.data)
        setSitlStatus(loginResp?.data?.data?.sitl_status)
        userDetails.droneID = loginResp?.data?.data?.droneId
        updateUserDetails()
        console.log("socket.emit update_token", { token: loginResp?.data?.data?.token })
        socket.emit("update_token", { token: loginResp?.data?.data?.token })
      }
      catch (err: any) {
        console.log(err)
        window.alert(err.response?.data?.message)
      }
    }
    else {
      window.alert("all fields are mandatory")
    }
  }

  return (
    <div className="flex flex-col items-center justify-start border border-black">
      <div className='text-xl font-bold'>Mission Planner Connection Manager</div>
      <div className='w-2/5 flex flex-row items-center justify-start border border-black px-4 py-1'>
        <div className='w-2/5'>User Email</div>
        <div>:</div>
        <input value={userDetails.userEmail} onChange={(e) => { userDetails.userEmail = e.target.value; updateUserDetails() }} type='email' className=''></input>
      </div>
      <div className='w-2/5 flex flex-row items-center justify-start border border-black px-4 py-1'>
        <div className='w-2/5'>Password</div>
        <div>:</div>
        <input value={userDetails.password} onChange={(e) => { userDetails.password = e.target.value; updateUserDetails() }} type='password' className=''></input>
      </div>
      <div className='w-2/5 flex flex-row items-center justify-start border border-black px-4 py-1'>
        <div className='w-2/5'>DroneId</div>
        <div>:</div>
        <input readOnly value={userDetails.droneID} onChange={(e) => { userDetails.droneID = e.target.value; updateUserDetails() }} type='text' className=''></input>
      </div>
      {/* <div className='w-1/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <div className='w-2/5 justify-self-start'>Connection Status</div>
        <div className=' justify-self-start'>:</div>
        <div className=''>{sitlStatus}</div>
      </div> */}
      <div className='w-2/5 flex flex-row items-center justify-around border border-black px-4 py-1'>
        <button onClick={handleLoginClick} className='p-1 border border-black bg-slate-400 hover:bg-black hover:text-white rounded-md'>Login</button>
        {/* <button onClick={handleStopSitlClick} className='p-1 border border-black bg-slate-400 hover:bg-black hover:text-white rounded-md'>Kill SITL</button> */}
      </div>
    </div>
  );
}

export default App;
